import React, { useEffect, useState, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import "react-perfect-scrollbar/dist/css/styles.css";

import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import reactLocalStorage from "utils/localStorage";
import useUser from "hooks/useUser";
import useTypeMember from "hooks/useTypeMember";
import { Divider, List, Typography } from 'antd';
import { ListItems } from "components";
import { LogoutOutlined } from "@ant-design/icons";

// import { useTranslation } from "react-i18next";
// import { getMe } from "services/User/index";
import * as S from "./style";

interface Props {
  userInfo?: any;
  sideBarMenu?: any;
  open?: boolean;
  infoWallet?: string;
}

interface userTypeProps {
  displayName?: string;
}

function SideBar(props: Props) {
  const { userInfo, sideBarMenu, open } = props;
  // const { t } = useTranslation("common");
  const { email, role } = useUser();
  const { typeMember } = useTypeMember();

  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const location = useLocation();
  const [userInfoAvtive, setUserInfoAvtive] = useState(false);
  const [userInfoData, setUserInfoData] = useState<userTypeProps>();

  useEffect(() => {
    getUserInfo()
  }, [])

  const getUserInfo = async () => {
    // let resp = await getMe()
    // console.log("getMe", resp)
    // setUserInfoData(resp?.data ?? {})
  }

  const handleActiveUserInfo = () => {
    setUserInfoAvtive(!userInfoAvtive);
  };

  const onLogOut = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    history.push(Routes.LOGIN);
    window.location.reload();
  };

  const selected = location.pathname

  return (
    <S.Sidebar>
      <S.Drawer
        // variant="persistent"
        // anchor="left"
        open={open}
        className={`sidebar ${open === true ? "open" : "close"}`}
      >
        <div className="sidebar-content">
          <div className="sidebar-scroll">
            <PerfectScrollbar>

              <div className="user-info">
                <Link
                  to="#"
                  className="user-info-top"
                  onClick={() => handleActiveUserInfo()}
                >
                  <img className="avatar" src={userInfo.avtSrc} alt="avatar" />
                  <div className="user-info-detail hiden-on-close">
                    <p className="user-info-name">Wellcome <br /><b>{email}</b></p>
                    {/* <p className="user-info-role">{role}</p> */}
                  </div>
                </Link>
                {
                  role === 'USER' && <S.TypeMember>
                    Membership: {typeMember}
                  </S.TypeMember>
                }

              </div>

              <List className="menu-list">
                {/* <h4 className="title-list">Quản lý hội viên</h4> */}
                {sideBarMenu && sideBarMenu.map((item?: any, index?: number) => {
                  return (
                    <Fragment key={index}>
                      <ListItems selected={selected} listData={item} />
                    </Fragment>
                  )
                })}
              </List>

              <List className="menu-list">
                {userInfo.subInfo.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={item.link}
                      className="sub-info"
                      onClick={onLogOut}
                    >
                      <span className="code-name">
                      <LogoutOutlined />
                      </span>
                      <Typography className="hiden-on-close sub-info-item">
                        {item.pageName}
                      </Typography>
                    </Link>
                  );
                })}
              </List>
            </PerfectScrollbar>
          </div>
        </div>
      </S.Drawer>
    </S.Sidebar>
  );
}

export default SideBar;
