import styled from 'styled-components'

export const Header = styled.div`
/* box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important; */
background-color: #00B250 !important;
padding: 0 20px;
position: fixed;
top: 0;
width: 100%;
left: 0;
right: 0;
z-index: 9;
.btn-action-sidebar{
  width: 41px;
  height: 41px;
  margin-left: 0;
  background: #ffffff;
  color: #333;
  border-color: #00B250;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  &:hover{
    background: #ffffff;
  }
}
.app-bar{
  background: transparent;
  box-shadow: none;
  position: static;
  padding: 10px 0;
  .MuiToolbar-gutters{
    padding: 0;
    min-height: auto;
  }
}
.navbar-dashboard{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .right-navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .center-navbar{
    background: #62a762;
    padding: 10px 30px;
    border-radius: 5px;
  }
}
.page-heading{
  background: transparent;
  box-shadow: none;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 300;
  line-height: 30px;
  padding: 12px 30px;
  min-width: 300px;
  justify-content: flex-start;
  &:hover{
    background: transparent;
    box-shadow: none;
  }
}
.icon-heading{
  background: transparent;
  box-shadow: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
  /* padding: 12px; */
  width: 41px;
  height: 41px;
  font-size: 20px;
  min-width: 41px;
  border-radius: 100%;
  &:hover{
    background: transparent;
    box-shadow: none;
  }
}
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputInput{
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }
  .searchIcon{
    width: 41px;
    height: 41px;
    font-size: 20px;
    min-width: 41px;
    padding-left: 12px;
    padding-right: 12px;
    color: #999;
    border-radius: 50%;
    background-color: #FFF;
    box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  }
}

.notify{
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  background: #62a762;
  color: #fff;
  height: 50px;
  width: 220px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`

export const AppBar = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`
export const Toolbar = styled.div`

`

export const Languages = styled.div`
width: 41px;
height: 41px;
display: flex;
justify-content: center;
align-items: center;
.language{
    display:flex;
    align-items:flex-end;
    flex-direction:column;
    justify-content:center;
    position: relative;
    div{
      cursor:pointer;
    }
  }
  .language-block{
    display: flex;
    align-items: center;
    img{
      margin: 0;
    }
  }
  .language-title{
    font-size: 16px;
    margin-right: 10px;
  }

  p{
    margin:0px;
  }
  img{
    /* width:21px;
    height: 15px; */
    margin-right:10px;
    object-fit: cover;
  }
  .headers{
    justify-content: space-between;
    align-items: center;
    > div{
      &.adrs-line{
        width: 70%;
        span{
          display: block;
          line-break: anywhere;
        }
      }
      &.language{
        width: 30%;
      }
    }
  }

  .pop-content{
    background-color: #ffffff;
    list-style-type: none;
    padding: 20px 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 1;
    min-width: 160px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
    span{
      color: #212121;
    }
    .close-btn{
      position: absolute;
      right: 7px;
      top: 5px;
      width: 30px;
      height: 30px;
      padding: 0;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #000000;
    }
    button{
      background: none;
      border: none;
      cursor: pointer;
      outline: none !important;
    }
    li{
      padding: 5px 10px;
      button{
        display: flex;
        font-size: 16px;
        align-items: center;
      }
    }
  }
`