import axios from 'services/axiosInstance'
import Endpoints from 'constants/endpoints'

export async function getAllBrand(params?: any) {
  return await axios().get(Endpoints.BRAND_LIST, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function getAllListBrand(params?: any) {
  return await axios().get(Endpoints.GET_BRAND_LIST, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function CreateBrand(data?: any) {
  return await axios().post(Endpoints.BRAND_CREATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function getDetailBrand(id?: any) {
  return await axios().get(Endpoints.BRAND_DETAIL+'/'+id)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function UpdateBrand(data?: any) {
  return await axios().post(Endpoints.BRAND_UPDATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function DeleteBrand(data?: any) {
  return await axios().post(Endpoints.BRAND_DELETE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}