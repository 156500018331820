import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllCategory } from 'services/Category'
import { AppThunk } from '../../redux/store'

interface CategoryState {
  id?: number,
  icon?: string
  name?: string
  description?: string
  create_time?: string
  update_time?: string
}

interface CategoryListState {
  listCategory?: CategoryState[]
}

const categoryInitialState: CategoryListState = {
  listCategory: []
}



const category = createSlice({
  name: 'Category',
  initialState: categoryInitialState,
  reducers: {
    setListCategory(state, { payload }: PayloadAction<any>) {
      state.listCategory = payload
    },
 
  }
})

export const {
  setListCategory,
} = category.actions

export const fetchListCategory = (): AppThunk => async (dispatch) => {
  // dispatch(setPopup())
  try {
    const response = await getAllCategory()
    console.log('response123 getAllCategory:::', response);
    dispatch(setListCategory(response.data))
  } catch (error) {
    // dispatch(logInFailure(error))
  }
}

export default category.reducer
