import React, { useState, useEffect, memo } from "react";
import {
  LineChartOutlined,
  UserOutlined,
  UserAddOutlined,
  PhoneOutlined,
  BankOutlined,
  CodepenCircleOutlined,
  ContactsOutlined,
  RiseOutlined,
  FallOutlined,
  SwapOutlined,
  TransactionOutlined,
  SettingOutlined,
  BarChartOutlined,
  ProjectOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import Avatar from "assets/images/faces/avatar.jpg";
import useUser from "hooks/useUser";

import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import VN from "assets/images/flags/VN.png";
import En from "assets/images/flags/GB.png";
import reactLocalStorage from "utils/localStorage";
import { fetchListCategory } from "features/category";
import { useDispatch } from "react-redux";
import { fetchListBrand } from "features/brand";
interface Props {
  boardContent?: any;
  getInfoWallet?: () => void;
}

const dataSidebar = [
  
  {
    titleMenu: `Thống kê`,
    icon: <BarChartOutlined />,
    link: Routes.ADMIN_DASHBOARD,
    subMenu: null,
  },
  {
    titleMenu: `Danh sách người dùng`,
    icon: <UserOutlined />,
    link: Routes.ADMIN_LIST_USER,
    subMenu: null,
  },
  {
    titleMenu: "Tạo người dùng mới",
    icon: <UserAddOutlined />,
    link: Routes.ADMIN_CREATE_USER,
    subMenu: null,
  },
  {
    titleMenu: "Tool đổi số điện thoại ",
    icon: <PhoneOutlined />,
    link: Routes.ADMIN_CHANGE_PHONE,
    subMenu: null,
  },
  // {
  //   titleMenu: `History Vote`,
  //   icon: <CodepenCircleOutlined />,
  //   link: Routes.ADMIN_LIST_VOTE,
  //   subMenu: null,
  // },

  {
    titleMenu: `Danh sách sản phẩm`,
    icon: <ProjectOutlined />,
    link: Routes.ADMIN_LIST_PRODUCT,
    subMenu: null,
  },
  {
    titleMenu: `Tạo sản phẩm`,
    icon: <CodepenCircleOutlined />,
    link: Routes.ADMIN_CREATE_PRODUCT,
    subMenu: null,
  },
  {
    titleMenu: `Danh sách bài viết`,
    icon: <ProjectOutlined />,
    link: Routes.ADMIN_LIST_NEWS,
    subMenu: null,
  },
  {
    titleMenu: `Tạo bài viết`,
    icon: <CodepenCircleOutlined />,
    link: Routes.ADMIN_CREATE_NEWS,
    subMenu: null,
  },
  {
    titleMenu: `Danh sách nhà cung cấp`,
    icon: <ProjectOutlined />,
    link: Routes.ADMIN_LIST_BRAND,
    subMenu: null,
  },
  {
    titleMenu: `Tạo nhà cung cấp`,
    icon: <CodepenCircleOutlined />,
    link: Routes.ADMIN_CREATE_BRAND,
    subMenu: null,
  },
  {
    titleMenu: `Danh sách đơn đặt hàng`,
    icon: <ShoppingCartOutlined />,
    link: Routes.ADMIN_LIST_ORDER,
    subMenu: null,
  },
  {
    titleMenu: `Danh sách rút tiền`,
    icon: <BankOutlined />,
    link: Routes.ADMIN_LIST_WITHDRAWAL,
    subMenu: null,
  },
  
  // {
  //   titleMenu: `Hoa hồng`,
  //   icon: <DollarCircleOutlined />,
  //   link: Routes.ADMIN_LIST_COMMISSION,
  //   subMenu: null,
  // },
  {
    titleMenu: `Cài đặt`,
    icon: <SettingOutlined />,
    link: Routes.ADMIN_SETTING,
    subMenu: null,
  },

  // {
  //   titleMenu: 'Create a new User',
  //   icon: <UserAddOutlined />,
  //   link: Routes.ADMIN_CREATE_USER,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `List Staking`,
  //   icon: <CodepenCircleOutlined />,
  //   link: Routes.ADMIN_LIST_STAKING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `List Investing`,
  //   icon: <LineChartOutlined />,
  //   link: Routes.ADMIN_LIST_INVESTING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `List History`,
  //   icon: <BankOutlined />,
  //   link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
  //   subMenu: [
  //     {
  //       titleMenu: `Deposit`,
  //       icon: <RiseOutlined />,
  //       // link: Routes.ADMIN_MEMBER_LIST + `/0`,
  //       link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Withdraw`,
  //       icon: <FallOutlined />,
  //       link: Routes.ADMIN_LIST_HISTORY_WITHDRAW,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Swap`,
  //       icon: <SwapOutlined  />,
  //       link: Routes.ADMIN_LIST_HISTORY_SWAP,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Transfer`,
  //       icon: <TransactionOutlined />,
  //       link: Routes.ADMIN_LIST_HISTORY_TRANSFER,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Invest`,
  //       icon: <BlockOutlined  />,
  //       link: Routes.ADMIN_LIST_HISTORY_INVEST,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Staking`,
  //       icon: <PicCenterOutlined   />,
  //       link: Routes.ADMIN_LIST_HISTORY_STAKING,
  //       subMenu: null,
  //     },
  //   ],
  // },
  // {
  //   titleMenu: `Setting`,
  //   icon: <SettingOutlined />,
  //   link: Routes.ADMIN_SETTING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `Change password`,
  //   icon: <LockOutlined  />,
  //   link: Routes.ADMIN_CHANGE_PASS,
  //   subMenu: null
  // },
];

const dataSidebarSupport = [
  {
    titleMenu: `List User`,
    icon: <UserOutlined />,
    link: Routes.ADMIN_LIST_USER,
    subMenu: null,
  },
  // {
  //   titleMenu: 'Create a new User',
  //   icon: <UserAddOutlined />,
  //   link: Routes.ADMIN_CREATE_USER,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `List Staking`,
  //   icon: <CodepenCircleOutlined />,
  //   link: Routes.ADMIN_LIST_STAKING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `List Investing`,
  //   icon: <LineChartOutlined />,
  //   link: Routes.ADMIN_LIST_INVESTING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `Change password`,
  //   icon: <LockOutlined  />,
  //   link: Routes.ADMIN_CHANGE_PASS,
  //   subMenu: null
  // },
];

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation("common");
  const { i18n } = useTranslation("common");
  const [open, setOpen] = useState(true);
  const { isAuthenticated, role } = useUser();

  const listOptions = [
    {
      src: VN,
      name: "Việt Nam",
      lang: "vn",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
  ];

  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: `${t("SIDEBAR_LOGOUT")}`,
        link: "#",
        isActive: false,
      },
    ],
  };

  const dataSideBars = role === "SUPPORT" ? dataSidebarSupport : dataSidebar;

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getLangSelected = (e) => {
    i18n.changeLanguage(e.lang);
    reactLocalStorage.set("langElectricity", JSON.stringify(e));

    // console.log("default", e)
  };

  useEffect(() => {
    let langSelected = JSON.parse(
      reactLocalStorage.get("langElectricity", JSON.stringify(listOptions[0]))
    ).lang;
    i18n.changeLanguage(langSelected);

    dispatch(fetchListCategory());
    dispatch(fetchListBrand());

    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        langSelected={getLangSelected}
        isOpen={open}
        listOptions={listOptions}
      />
      <S.Body>
        <SideBar
          userInfo={userInfo}
          sideBarMenu={dataSideBars}
          open={open}
          // infoWallet={infoWallet}
        />
        <main className="main-content">
          <div className="board-content">{boardContent}</div>
        </main>
      </S.Body>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
