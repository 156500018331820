import styled from 'styled-components'

export const DateTimePicker = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  /* z-index: 9; */
  &.active{
    border-color: red;
  }
  .icon-date-picker{
    padding-right: 5px;
    width: 25px;
    position: absolute;
    top: 7px;
    right: 0;
  }
  .react-datepicker-wrapper{
    z-index: 2;
  }
  .react-datepicker-popper{
    z-index: 2;
  }
  .react-datepicker__day-names {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
    }
    .react-datepicker__input-container {
      input {
        padding: 3px 10px;
        border-radius: 5px;
        outline: none;
        font-size: 14px;
        border: none;
        background: transparent;
        cursor: pointer;
        height: 34px;
      }
    }
    .react-datepicker__header {
      background: #00B250;
      .react-datepicker__current-month {
        color: #ffffff;
      }
      .react-datepicker__day-name {
        color: #ffffff;
      }
    }
    .react-datepicker__day {
      margin: 5px;
      &:hover {
        border-radius: 50%;
      }
    }
    .react-datepicker__day--selected {
      background: #00B250;
      border-radius: 50%;
    }
    .react-datepicker__week {
      font-size: 13px;
    }
    .react-datepicker__navigation--previous {
      left: 7px;
      border-right-color: #ffffff;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ffffff;
    }
    .react-datepicker {
      border: 1px solid #00B250;
    }
    .react-datepicker__triangle {
      border-bottom-color: #00B250 !important;
      left: 95px !important;
    }
`