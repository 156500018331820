import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllBrand } from 'services/Brand'
import { AppThunk } from '../../redux/store'

interface BrandState {
  id?: number,
  icon?: string
  name?: string
  description?: string
  create_time?: string
  update_time?: string
}

interface BrandListState {
  listBrand?: BrandState[]
}

const brandInitialState: BrandListState = {
  listBrand: []
}



const brand = createSlice({
  name: 'Brand',
  initialState: brandInitialState,
  reducers: {
    setListBrand(state, { payload }: PayloadAction<any>) {
      state.listBrand = payload
    },
 
  }
})

export const {
  setListBrand,
} = brand.actions

export const fetchListBrand = (): AppThunk => async (dispatch) => {
  // dispatch(setPopup())
  try {
    const response = await getAllBrand()
    
    dispatch(setListBrand(response.data))
  } catch (error) {
    // dispatch(logInFailure(error))
  }
}

export default brand.reducer
