import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import { Button,  } from "antd";

import { MoreOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import Routes from "constants/routes";
import { logOut } from "features/user/userSlice";
import reactLocalStorage from "utils/localStorage";

import * as S from "./style";

interface Props {
  handleDrawerOpen?: () => void;
  langSelected?: (item: any) => void;
  isOpen?: boolean;
  listOptions?: any;
}

function Header(props: Props) {
  const { handleDrawerOpen, isOpen, listOptions, langSelected } = props;
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  // const [activeDropdown, setActiveDropdown] = useState(false);
  const [langActive, setLangActive] = useState(listOptions[0]);

  const onLogOut = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    history.push(Routes.LOGIN);
    window.location.reload();
  };

  // const onHandleClick = (item) => {
  //   setLangActive(item);
  //   // i18n.changeLanguage(item.lang)
  //   langSelected(item);
  //   setActiveDropdown(false);
  // };

  useEffect(() => {
    let lang = JSON.parse(
      reactLocalStorage.get("langElectricity", JSON.stringify(langActive))
    );
    setLangActive(lang);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Header>
      <S.AppBar className="app-bar">
        <S.Toolbar className="navbar-dashboard">
          <div className="left-navbar">
            <Button
              type="primary"
              shape="circle"
              icon={
                isOpen === true ? (
                  <MenuOutlined className="arrow" />
                ) : (
                  <MoreOutlined className="arrow" />
                )
              }
              size={"large"}
              onClick={handleDrawerOpen}
              className={`btn-action-sidebar ${
                isOpen === true ? "btn-open" : "btn-close"
              }`}
            />

            {/* <IconButton
              color="default"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`btn-action-sidebar ${
                isOpen === true ? "btn-open" : "btn-close"
              }`}
            >
              {isOpen === true ? (
                <MenuOutlined className="arrow" />
              ) : (
                <MoreOutlined className="arrow" />
              )}
            </IconButton> */}
          </div>

          <div className="right-navbar">
            {/* <UserWallet account={account} login={login} logout={logout} /> */}
            {/* <Button
                variant="contained"
                color="primary"
                className="icon-heading"
                // onClick={getNotification}
              >
                <NotificationOutlined />
              </Button> */}

            <Button
                // color="primary"
                className="icon-heading"
                onClick={onLogOut}
              >
                <LogoutOutlined/>
              </Button>
          </div>
        </S.Toolbar>
      </S.AppBar>
    </S.Header>
  );
}

export default memo(Header);
