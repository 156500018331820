const Endpoints = {
  DOWNLOAD: '/report',
  LOGIN: '/api/v1/user/login',
  DASHBOARD: '/api/admin/dashboard/get',
  USER_LIST: '/api/admin/user/list',
  USER_LIST_TRADE: '/api/v1/public/user/list-trade',
  GET_USER_DETAIL: 'api/admin/user/detail',
  GET_USER_CREATE: '/api/v1/user/register',
  GET_USER_UPDATE: '/api/admin/user/update',
  GET_USER_DELETE: '/api/admin/user/delete',
  GET_USER_STAKING: '/api/v1/public/user/staking-admin',
  GET_USER_INVEST: '/api/v1/public/user/buy-admin',
  GET_USER_CHANGE_PASS: '/api/v1/public/auth/change-password',
  GET_USER_CHANGE_PHONE: '/api/admin/user/change-phone',
  GET_SETTING: '/api/v1/public/settings/details',
  UPDATE_SETTING: '/api/v1/public/settings/update',
  CONFIRM_KYC: '/api/v1/public/user/confirm-kyc',
  GET_RATE: '/api/admin/commission/get-rate',
  UPDATE_RATE: '/api/admin/commission/update-rate',

  PRODUCT_LIST: '/api/v1/product/list',
  PRODUCT_DETAIL: '/api/v1/product',
  PRODUCT_UPDATE: '/api/admin/product/update',
  PRODUCT_CREATE: '/api/admin/product/create',
  PRODUCT_DELETE: '/api/admin/product/delete',

  NEWS_LIST: '/api/admin/blog/list',
  NEW_CREATE: '/api/admin/blog/create',
  NEW_DETAIL: '/api/admin/blog/detail',
  NEW_UPDATE: '/api/admin/blog/update',
  NEW_DELETE: '/api/admin/blog/delete',

  GET_BRAND_LIST: '/api/admin/brand/list',
  BRAND_CREATE: '/api/admin/brand/create',
  BRAND_DETAIL: '/api/admin/brand/detail',
  BRAND_UPDATE: '/api/admin/brand/update',
  BRAND_DELETE: '/api/admin/brand/delete',

  ORDER_LIST: '/api/admin/order/list',
  ORDER_CONFIRM: '/api/admin/order/complete-order',
  ORDER_CANCEL: '/api/admin/order/cancel-order',

  WITHDRAWAL_LIST: '/api/admin/withdraw/list',
  WITHDRAWAL_CONFIRM: '/api/admin/withdraw/complete-withdraw',
  WITHDRAWAL_CANCEL: '/api/admin/withdraw/cancel-withdraw',
  
  COMMISSION_LIST: '/api/admin/commission/list',


  SINGLE_UPLOAD: "/upload/single-file",
  IMAGE_UPLOAD: "/api/v1/image/upload",

  CATEGORY_LIST: '/api/v1/category/list',
  BRAND_LIST: '/api/v1/brand/list',


  GET_DEPOSIT_HISTORY_LIST: '/api/v1/public/deposit-history/list-by-user',
  GET_WITHDRAW_HISTORY_LIST: '/api/v1/public/withdraw-history/list-by-user',
  GET_CONFIRM_WITHDRAW_HISTORY: '/api/v1/public/withdraw-history/confirm',
  GET_SWAP_HISTORY_LIST: '/api/v1/public/swap-history/list-by-user',
  GET_TRANSFER_HISTORY_LIST: '/api/v1/public/transfer-history/list-by-user',
  GET_INVEST_HISTORY_LIST: '/api/v1/public/user-package/list-invest-by-user',
  GET_STAKING_HISTORY_LIST: '/api/v1/public/user-package/list-stake-by-user',

  BUY_PACKAGE: '/api/v1/public/user-package/buy-package',
  GET_LIST_PACKAGE: '/api/v1/public/user-package/list',
  GET_LIST_INVITER: '/api/v1/public/report/list-inviter-by-user',

  GET_STAKING_LIST: '/api/v1/public/user-package/list-by-user',
  GET_INVESTING_LIST: '/api/v1/public/package/list-investing',
  GET_PACKAGE_UPDATE: '/api/v1/public/package/update',

  GET_BONUS_LIST: '/api/v1/public/bonus/list-bonus-by-user',
  GET_BONUS_UPDATE: '/api/v1/public/user/update-bonus',

  CHANGE_PASSWORD: '/api/v1/public/auth/change-password',

  GET_ALL_MEMBER: '/api/v1/admin/list-user',

  GET_ALL_COUNTRY: '/api/v1/public/country/list',

  GET_ALL_CONTACT: '/api/v1/public/contact/getAll',

}

export default Endpoints