import React, { Suspense, lazy, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Routes from "constants/routes";
import useUser from "hooks/useUser";
import GlobalStyles from "./global-styles";
import { LoadSpinner } from "components";
import { DefaultLayout } from "layouts";
import Loading from "components/Loading";

const LoginPage = lazy(() => import("pages/LoginPage"));

const UserManage = lazy(() => import("pages/UserManage"));
const UserDetail = lazy(() => import("pages/UserManage/UserDetail"));
const UserCreate = lazy(() => import("pages/UserManage/UserCreate"));
const ChangePhone = lazy(() => import("pages/UserManage/ChangePhone"));

const ProductManage = lazy(() => import("pages/ProductManage"));
const ProductCreate = lazy(() => import("pages/ProductManage/CreateProduct"));
const ProductDetail = lazy(() => import("pages/ProductManage/DetailProduct"));
const OrderManage = lazy(() => import("pages/OrderManage"));
const CommissionManage = lazy(() => import("pages/CommissionManage"));
const Withdrawal = lazy(() => import("pages/Withdrawal"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const NewsManage = lazy(() => import("pages/NewsManage"));
const NewsCreate = lazy(() => import("pages/NewsManage/CreateNews"));
const NewsDetail = lazy(() => import("pages/NewsManage/DetailNews"));

const BrandManage = lazy(() => import("pages/BrandManage"));
const BrandCreate = lazy(() => import("pages/BrandManage/CreateBrand"));
const DetailBrand = lazy(() => import("pages/BrandManage/DetailBrand"));

// const UserCreate = lazy(() => import("pages/UserManage/UserCreate"));
const StakingManage = lazy(() => import("pages/StakingManage"));
const VoteManage = lazy(() => import("pages/VoteManage"));
// const InvestingManage = lazy(() => import("pages/InvestingManage"));
// const HistoryManage = lazy(() => import("pages/HistoryManage"));
// const DepositManage = lazy(() => import("pages/HistoryManage/Deposit"));
// const WithdrawManage = lazy(() => import("pages/HistoryManage/Withdraw"));
// const SwapManage = lazy(() => import("pages/HistoryManage/Swap"));
// const TransferManage = lazy(() => import("pages/HistoryManage/Transfer"));
// const Invest = lazy(() => import("pages/HistoryManage/Invest"));
// const Staking = lazy(() => import("pages/HistoryManage/Staking"));

const InviteManage = lazy(() => import("pages/InviteManage"));
const SettingManage = lazy(() => import("pages/SettingManage"));
// const ChangePassword = lazy(() => import("pages/ChangePassword"));

const Errors = lazy(() => import("pages/Errors"));

const renderLoader = () => <LoadSpinner isOverlay={false} />;

const AppWrapper: any = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    .ant-layout-content {
      background-color: ${(props) => props.theme.innerContBg};
    }
  }
  .ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0;
    line-height: inherit;
    z-index: 99;
    position: sticky;
    top: 0;
  }
`;

function App() {
  const location = useLocation();
  const { isAuthenticated, role } = useUser();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   isAuthenticated && dispatch(fetchNetwork())
  // }, [dispatch,isAuthenticated])

  function PrivateRoute({ component: TargetPage, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  function AdminRoute({ component: TargetPage, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true && role === "ADMIN" ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  function PublicRoute({
    component: TargetPage,
    ...rest
  }: any): React.ReactElement {
    return (
      <Route
        {...rest}
        render={(props: any) => {
          return <TargetPage {...props} {...rest} />;
        }}
      />
    );
  }
  return (
    <AppWrapper>
      {isAuthenticated === false ? (
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Redirect exact from="/" to={Routes.LOGIN} />
            <Route path={Routes.LOGIN} component={LoginPage} />
          </Suspense>
        </Switch>
      ) : (
        <DefaultLayout
          // pageHeading={t("STATISCAL_HEADING")}
          boardContent={
            <Switch>
              <Suspense fallback={renderLoader()}>
                <Route path={Routes.LOGIN} component={LoginPage} />
                {location.pathname === "/login" && role !== "USER" && (
                  <Redirect exact from="/login" to={Routes.ADMIN_LIST_USER} />
                )}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to={Routes.ADMIN_LIST_USER} />;
                  }}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_USER}
                  component={UserManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_USER}
                  component={UserCreate}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_PHONE}
                  component={ChangePhone}
                />
                
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DETAIL_USER + `/:id`}
                  component={UserDetail}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_PRODUCT}
                  component={ProductManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_PRODUCT}
                  component={ProductCreate}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DETAIL_PRODUCT + `/:id`}
                  component={ProductDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_NEWS}
                  component={NewsManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_NEWS}
                  component={NewsCreate}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DETAIL_NEWS + `/:id`}
                  component={NewsDetail}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_BRAND}
                  component={BrandManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_BRAND}
                  component={BrandCreate}
                />
                 <PrivateRoute
                  exact
                  path={Routes.ADMIN_DETAIL_BRAND + `/:id`}
                  component={DetailBrand}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_ORDER}
                  component={OrderManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_WITHDRAWAL}
                  component={Withdrawal}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_COMMISSION}
                  component={CommissionManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_INVITER}
                  component={InviteManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SETTING}
                  component={SettingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DASHBOARD}
                  component={Dashboard}
                />

                {/* <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_INVITER}
                  component={InviteManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_STAKING}
                  component={StakingManage}
                />

                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_VOTE}
                  component={VoteManage}
                />
               */}

                {/* <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_USER}
                  component={UserCreate}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_STAKING}
                  component={StakingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_INVESTING}
                  component={InvestingManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY}
                  component={HistoryManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_DEPOSIT}
                  component={DepositManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_WITHDRAW}
                  component={WithdrawManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_INVITER}
                  component={InviteManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_SWAP}
                  component={SwapManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_TRANSFER}
                  component={TransferManage}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_INVEST}
                  component={Invest}
                />
                <AdminRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_STAKING}
                  component={Staking}
                />

                <AdminRoute
                  exact
                  path={Routes.ADMIN_SETTING}
                  component={SettingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_PASS}
                  component={ChangePassword}
                /> */}

                <AdminRoute
                  exact
                  path={Routes.ADMIN_ERRORS}
                  component={Errors}
                />
              </Suspense>
            </Switch>
          }
        />
      )}
      <Loading />
      <GlobalStyles />
    </AppWrapper>
  );
}

export default App;
